<template>
  <section
    class="section"
    :class="sectionBackgroundClass(background)"
  >
    <div class="container">
      <h2
        v-if="titleEnabled"
        class="as-h3 mb-8 flex items-center justify-center gap-2 lg:mb-14 lg:justify-end"
      >
        <a
          :href="`https://instagram.com/${title}`"
          target="_blank"
          rel="nofollow noopener"
          class="link flex items-center gap-2"
        >
          @{{ title }}
          <UiIcon
            name="instagram"
            class="-mt-1 inline fill-black lg:size-9"
          />
        </a>
      </h2>

      <div class="container-extend overflow-x-auto py-1">
        <UiLoader
          :has-data="!!(feed?.length)"
          :loading="pending"
          empty-message="Deze feed is nog leeg 🥲"
        >
          <div class="container-inset flex snap-x snap-mandatory gap-9 md:grid md:grid-cols-3">
            <a
              v-for="post in feed"
              :key="post.id"
              :href="post.permalink"
              class="block snap-proximity snap-center transition hover:ring-2 hover:ring-primary max-md:flex-[1_0_100%]"
              target="_blank"
              rel="nofollow noopener"
            >
              <img
                class="image-fill aspect-square"
                loading="lazy"
                :src="post.mediaUrl"
                :alt="post.prunedCaption"
              >
            </a>
          </div>
        </UiLoader>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type {SectionProps} from '~/types/section';

const props = defineProps<SectionProps>();

type InstagramPost = {
  caption: string
  colorPalette: {
    dominant: string
    mutedLight: string
    vibrant: string
  }
  dimensions: {
    height: number
    width: number
  }
  hashtags: string[]
  id: string
  mediaType: 'IMAGE'
  mediaUrl: string
  permalink: string
  prunedCaption: string
  timestamp: string
}

const id = useId();
const url = toRef(() => props.url);

const {data: feed, pending} = await useAsyncData<InstagramPost[]>(`instagram-${id}`, async function () {
  if (!url.value) {
    return [];
  }

  return $fetch(url.value);
}, {
  watch: [url],
});
</script>
